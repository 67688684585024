import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Menu } from "src/app/interfaces/menu.model";
import {
    SessionService,
    SignIn,
} from "src/app/services/session/session.service";
import { ModalContactComponent } from "src/app/shared/modal-contact/modal-contact.component";
import { ModalSignInComponent } from "src/app/shared/modal-sign-in/modal-sign-in.component";
import { SidenavMenuService } from "./sidenav-menu.service";

@Component({
    selector: "app-sidenav-menu",
    templateUrl: "./sidenav-menu.component.html",
    styleUrls: ["./sidenav-menu.component.scss"],
    providers: [SidenavMenuService],
})
export class SidenavMenuComponent implements OnInit {
    @Input("menuItems") menuItems;
    @Input("menuParentId") menuParentId;
    public userInformation: SignIn;
    parentMenu: Array<any>;
    menu: Array<Menu> = [];
    topMenu: Array<any> = [
        //NÃO REMOVER O SIGNIN DESTE ID
        {
            id: 7,
            label: "Login",
            ordem: 7,
            route: "/",
            subMenu: [],
            href: true,
            selected: true,
        },
        {
            id: 0,
            label: "Home",
            ordem: 0,
            route: "/",
            subMenu: [],
            fragment: false,
        },
        {
            id: 2,
            label: "Rugs",
            ordem: 2,
            route: "/products/tapetes/000151/2",
            subMenu: [],
            fragment: false,
        },
        //   {
        //     id: 3,
        //     label: "Tecidos",
        //     ordem: 2,
        //     route: "/products/tecidos/000097/2",
        //     subMenu: [],
        //     fragment: false,
        //   },
        {
            id: 5,
            label: "Catalog",
            ordem: 4,
            route: "",
            subMenu: [],
            fragment: false,
            href: false,
        },
        {
            id: 4,
            label: "Rug Care",
            ordem: 3,
            route: "/rug-care",
            subMenu: [
                {
                    ordem: 1,
                    descricao: "General Tips",
                    codigo: "1",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 95,
                    descricao: "Wool",
                    codigo: "2",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 96,
                    descricao: "Wool/ Blend",
                    codigo: "2",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 97,
                    descricao: "Jute",
                    codigo: "2",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 98,
                    descricao: "Viscose / Blend",
                    codigo: "2",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 99,
                    descricao: "PET Yarn",
                    codigo: "2",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 3,
                    descricao: "FAQ´s x Tips",
                    codigo: "3",
                    route: "/rug-care",
                    linkInterno: true,
                },
                {
                    ordem: 4,
                    descricao: "Rug Warranty",
                    codigo: "4",
                    route: "/rug-care",
                    linkInterno: true,
                },
            ],
            fragment: false,
        },
        {
            id: 6,
            label: "Events",
            ordem: 5,
            route: "/events",
            fragment: false,
            subMenu: [
                {
                    codigo: "27",
                    descricao: "Events",
                    ordem: 0,
                    route: "/events",
                    linkInterno: true,
                },
                {
                    codigo: "28",
                    descricao: "Media",
                    ordem: 1,
                    route: "/media",
                    linkInterno: true,
                },
            ],
        },
        // { id: 6, label: 'Media', ordem: 6, route: '/media', subMenu: [], fragment: false },
        {
            id: 1,
            label: "About Us",
            ordem: 1,
            route: "/about",
            subMenu: [],
            fragment: false,
        },
        {
            id: 8,
            label: "Contact",
            ordem: 8,
            route: "/contact",
            subMenu: [],
            fragment: false,
            function: true,
        },
        //   {
        //     id: 9,
        //     label: "Onde comprar",
        //     ordem: 9,
        //     route: "/onde-comprar",
        //     subMenu: [],
        //     fragment: false
        //   },
        {
            id: 9,
            label: "Samples Program",
            ordem: 9,
            route: "/samples-program",
            subMenu: [],
            fragment: false,
        },

        //NAO MUDAR O ID
        {
            id: 10,
            label: "Open Trade Account",
            ordem: 10,
            route: "/open-trade-account",
            subMenu: [],
            fragment: false,
        },
    ];

    isOpen: boolean = false;
    logged: boolean = false;

    public user = {
        logged: "https://minori-casa.s3.amazonaws.com/site-images/icons/account-user-logged.png",
    };

    public topMenuAux = this.topMenu;
    public codTabelaPreco: string;

    constructor(
        private sidenavMenuService: SidenavMenuService,
        private sessionService: SessionService,
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.sessionService.isLogged$.subscribe((data) => {
            if (data) {
                let indiceArray = this.topMenu.findIndex(
                    (i) => i.id === 7 || i.id === 10
                );
                if (indiceArray != -1) {
                    this.topMenu.splice(indiceArray, 1);
                    this.logged = true;
                }
            } else {
                this.topMenu = this.topMenuAux;
                this.logged = false;
            }

            this.topMenu.forEach((item) => {
                if (item.id === 5) {
                    // if (this.logged) {
                    item.route = "https://catalog.minoricasa.com/";
                    // } else {
                    //     item.route = "https://campaign.minoricasa.com/catalog";
                    // }
                }
            });
        });

        if (this.logged) {
            this.topMenu.push({
                id: 11,
                label: "Logout",
                ordem: 11,
                route: "/logout",
                subMenu: [],
                fragment: false,
                function: true,
            });
        }

        this.sessionService.codTabelaPreco$.subscribe(
            (data) => (this.codTabelaPreco = data)
        );
        this.sessionService.information$.subscribe((data: SignIn) => {
            this.userInformation = data;
        });

        console.log(this.logged);
    }

    onClick(menuId, tipoMenu) {
        this.sidenavMenuService.toggleMenuItem(menuId);
        this.sidenavMenuService.closeOtherSubMenus(tipoMenu, menuId);
    }

    public goTo(item: Menu) {
        if (
            item.route.indexOf("http") !== -1 ||
            item.route.indexOf("www") !== -1
        ) {
            window.open(item.route, "blank");
        } else if (item.fragment) {
            const frament = item.fragmentString ? item.fragmentString : "";
            this.router.navigate([item.route], { fragment: frament });
        } else if (item.function) {
            if (item.route.includes("contact")) {
                this.openDialogContact();
            } else if (item.route.includes("logout")) {
                this.logout();
            }
        } else if (item.href) {
            this.openDialogSignIn();
        } else {
            this.router.navigate([item.route]);
        }
    }

    public openDialogContact() {
        let isMobile = {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Windows: function () {
                return (
                    navigator.userAgent.match(/IEMobile/i) ||
                    navigator.userAgent.match(/WPDesktop/i)
                );
            },
            any: function () {
                return (
                    isMobile.Android() ||
                    isMobile.BlackBerry() ||
                    isMobile.iOS() ||
                    isMobile.Opera() ||
                    isMobile.Windows()
                );
            },
        };
        if (isMobile.any() === null) {
            this.dialog.open(ModalContactComponent, {
                data: {},
                height: "650px",
                width: "650px",
                maxWidth: "50vw",
                maxHeight: "100vh",
                panelClass: "dialog-container-arredondado",
            });
        } else {
            this.dialog.open(ModalContactComponent, {
                data: {},
                maxWidth: "100vw",
                maxHeight: "100vh",
                height: "100%",
                width: "100%",
                panelClass: "dialog-container-arredondado",
            });
        }
    }

    public openDialogSignIn() {
        this.dialog.open(ModalSignInComponent, {
            data: {},
            maxWidth: innerWidth * 0.95,
            maxHeight: innerHeight * 0.95,
        });
    }

    public emitMenuSelecionado(item: any) {
        this.router.navigate(["/rug-care"]);
        this.sessionService.emitRugCare.emit(item);
    }

    logout() {
        this.sessionService.logout();
        this.sessionService.isLoggedIn$.next(false);
        this.sessionService.emitLogin.emit(false);
        location.reload();
        this.snackBar.open("Disconnected successfully", "", {
            panelClass: "success",
            verticalPosition: "bottom",
            duration: 3000,
        });
    }

    toggleContent() {
        this.isOpen = !this.isOpen;
        //  this.contentHeight = this.isOpen ? this.content.nativeElement.scrollHeight + 'px' : '0px';
    }
}
